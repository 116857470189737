<template>
      <div class="text-center" v-if="!is_data_ready">
          <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
          </div>
      </div>
      <div class="sabc_video_banner_sec sabc_videos_page_body" v-else-if="is_data_ready">
          <!-- sabc video slider start -->
          <section class="header_video">
              <div class="swiper headerVideos">
                  <!-- Indicators -->
                  <!-- The slideshow -->
                  <div class="swiper-wrapper">
                      <!-- <div class="swiper-slide" v-for="firstCategory in header.video_list">
                          <div class="sabc_video_slider_box">
                              <img :src="base_url+firstCategory.image" :alt="firstCategory.title"/>
                              <div class="carousel-desc">
                                  <div class="container">
                                      <label><router-link :to="{ name: 'video_category', params: { category: header.slug}}">{{firstCategory.category}}</router-link></label>
                                      <h1>{{firstCategory.title}}</h1>
                                      <p></p>
                                      <router-link class="carousel_desc_watch" :to="{ name: 'video_player', params: { category: header.slug, player: firstCategory.id}}">Watch now</router-link>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="swiper-slide" v-for="firstCategory in category_list" :key="firstCategory.id">
                          <div class="sabc_video_slider_box" v-if="firstCategory.image != null">
                              <img :src="$storageURL+'images/video/'+firstCategory.image" :alt="firstCategory.title"/>
                              <div class="carousel-desc">
                                  <div class="container">
                                      <label><router-link :to="{ name: 'video_category', params: { category: firstCategory.slug}}">{{firstCategory.title}}</router-link></label>
                                      <h1>{{firstCategory.title}}</h1>
                                      <p></p>
                                      <router-link class="carousel_desc_watch" :to="{ name: 'video_category', params: { category: firstCategory.slug}}">Watch now</router-link>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- Left and right controls -->
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                  <div class="swiper-pagination"></div>
              </div>
          </section>
          <!-- sabc video slider end  -->
          <!-- Video category tabs start -->
          <section class="container">
              <div class="sabc_video_section category_list_area">
                  <div class="sabc_video_tabs swiper">
                      <ul class="swiper-wrapper">
                          <li class="swiper-slide" v-for="(catName, index) in category_list" :key="index">
                              <router-link :to="{ name: 'video_category', params: { category: catName.slug}}">{{catName.title}}</router-link>
                          </li>
                      </ul>
                      <div class="swiper-button-prev1"></div>
                      <div class="swiper-button-next1"></div>
                  </div>
              </div>
          </section>
          <!-- Video category tabs end -->
          <!-- video area start -->
          <section class="container" v-for="catList in body.category_list" :key="catList.id">
              <div class="sabc_video_section category_video_list">
                  <div class="sabc_video_title">
                      <h2>
                        <router-link :to="{ name: 'video_category', params: { category: catList.slug}}">{{catList.title}}</router-link>
                      </h2>
                  </div>
                  <div class="sabc_video_list swiper videolist">
                      <div class="swiper-wrapper">
                          <div class="swiper-slide" v-for="catVideo in catList.video_list" v-if="catVideo.image != null" :key="catVideo.id">
                              <ul>
                                  <li>
                                      <router-link :to="{ name: 'video_player', params: { category: catList.slug, player: catVideo.slug}}">
                                          <div class="sabc_video_listpic">
                                              <!-- <img :src="imagePath(base_url+catVideo.image)" :alt="catVideo.title" loading="lazy"/> -->
                                              <source media="(min-width: 575px)" :srcset="imagePath(base_url+catVideo.image)">
                                              <img :src="imagePath(base_url+catVideo.image)" width="444" height="249"  :alt="catVideo.title" loading="lazy">
                                              <span></span>
                                              <label>{{new Date(catVideo.duration*1000).toISOString().slice(11, 19)}}</label>
                                          </div>
                                          <div class="sabc_video_listdesc">
                                              <h2>{{catVideo.title}}</h2>
                                          </div>
                                      </router-link>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <!-- video area end -->
      </div>
</template>

<script>
    import { Swiper, Navigation, Pagination  } from "swiper";
    Swiper.use([ Navigation, Pagination  ]);
    export default {
        name: 'videolising',
        data() {
            return {
                base_url:'',
                header: [],
                body: [],
                category_list: [],
                is_data_ready:false
            }
        },
        components: {},
        async mounted() {
            await this.getVideoData();
            await this.loadSwiper();
        },
       watch: {
       },
        serverPrefetch() {
            this.getVideoData();
        },
        computed: {
        },
        metaInfo() {
           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                let title = this.$getSafe(() => ss.sabc_media_listing_meta_title);
                let description = this.$getSafe(() => ss.sabc_media_listing_meta_description);
                let keywords = this.$getSafe(() => ss.sabc_media_listing_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },

        methods: {
            getVideoData() {
                return this.$axios.get(this.$GetVideoList)
                        .then(response => {
                            if (response.data) {
                                this.base_url = response.data.base_url;
                                this.header = response.data.header;
                                this.body = response.data.body;
                                this.category_list = response.data.category_list;
                                this.is_data_ready = true;
                            }
                        })
                        .catch(error => {
                        })
            },
            loadSwiper(){
                new Swiper(".sabc_video_tabs", {
                    slidesPerView:5.5,
                    spaceBetween: 10,
                    autoplay: false,
                    breakpoints: {
                        1199: {
                            slidesPerView: 5.5,
                        },
                        991: {
                            slidesPerView: 3,
                        },
                        480: {
                            slidesPerView: 1.5,
                            spaceBetween: 16,
                        },
                        200: {
                            slidesPerView: 1.5,
                            spaceBetween: 16,
                        },

                    },
                    navigation: {
                        nextEl: ".swiper-button-next1",
                        prevEl: ".swiper-button-prev1",
                    },
                });
                new Swiper(".headerVideos", {
                    slidesPerView:1,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                });
                new Swiper(".videolist", {
                    slidesPerView: 4,
                    observer: true,
                    observeParents: true,
                    paginationClickable: true,
                    spaceBetween: 24,
                    autoplay: false,
                    breakpoints: {
                        1199: {
                            slidesPerView: 4,
                        },
                        991: {
                            slidesPerView: 3,
                        },
                        480: {
                            slidesPerView: 1.5,
                            spaceBetween: 16,
                        },
                        200: {
                            slidesPerView: 1.5,
                            spaceBetween: 16,
                        },

                    },
                });
            },
            imagePath(path){
                let lastSplit = path.replace(/\\/g,'/').replace(/.*\//, '').replace(/.jpeg/, '')+'_444x249.webp';
                return this.$storageURL+'images/video/'+lastSplit;
            }
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/videos.scss';
</style>
